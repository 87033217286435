import React from "react";
import {
  HeaderActionQRCode,
  HeaderActionShare,
} from "../../../components/HeaderActions";
import { usePartyQuery } from "../../../data/parties/queries";

type Props = {
  isHost: boolean;
};

const PartyHeaderActions = ({ isHost }: Props) => {
  const { data: party } = usePartyQuery();

  return (
    <div className="flex flex-row items-center justify-center gap-7 px-6 pt-2 pb-2">
      <HeaderActionQRCode
        QRTitle={party.name}
        title="Invite"
        url={`https://app.amplifimusic.com/party/${party.id}`}
      />
      <HeaderActionShare
        title="Share"
        url={`https://app.amplifimusic.com/party/${party.id}`}
      />
      {/* <HeaderActionSpotify /> */}
    </div>
  );
};

export default PartyHeaderActions;
