import { useAuthSignInWithPopup } from "@react-query-firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { auth } from "../../utils/firebase";

type LocationState = { path: string };

function LoginView() {
  const navigate = useNavigate();
  const signInMutation = useAuthSignInWithPopup(auth, {
    onError: () => console.log("An error occured"),
  });
  const location = useLocation();
  const state = location.state as LocationState;

  async function onLogin() {
    try {
      await signInMutation.mutateAsync({ provider: new GoogleAuthProvider() });
      console.log("Logged in");
      navigate(state?.path || "/");
    } catch (err) {
      console.log("we caught an error", err);
    }
  }

  return (
    <div>
      <Header leftButtonType="back" title="Login" centerTitle />
      <div className="flex justify-center mt-12">
        <button className="btn btn-gradient" onClick={onLogin}>
          Login With Google
        </button>
      </div>
    </div>
  );
}

export default LoginView;
