import {
  useFirestoreQuery,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import {
  collection,
  collectionGroup,
  CollectionReference,
  orderBy,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useQuery } from "react-query";
import { Set } from "typescript";
import { firestore, functions } from "../../utils/firebase";
import { Tip } from "./types";
import { songRequestConverter } from "./converters";
import { SongInfo } from "./types";

const getSpotifySearch = httpsCallable(functions, "https-getSpotifySearch");
const getSpotifyTop50 = httpsCallable(functions, "https-getSpotifyTop50");

function useSongsQueryGeneric(
  partyId: string,
  queryConstraints: QueryConstraint[],
  keyName: string
) {
  const songRequestsRef = collection(
    firestore,
    `parties/${partyId}/songRequests`
  ).withConverter(songRequestConverter);

  const q = query(songRequestsRef, ...queryConstraints);

  return useFirestoreQueryData(
    ["songRequests", keyName, partyId],
    q,
    {
      subscribe: true,
    },
    { cacheTime: 0 }
  );
}

export function useNormalSongsQuery(partyId: string) {
  return useSongsQueryGeneric(
    partyId,
    [
      where("played", "==", false),
      where("totalTips", "==", 0),
      orderBy("numUpvotes", "desc"),
    ],
    "normal"
  );
}

export function useTippedSongsQuery(partyId: string) {
  return useSongsQueryGeneric(
    partyId,
    [
      where("played", "==", false),
      where("totalTips", ">", 0),
      orderBy("totalTips", "desc"),
    ],
    "tipped"
  );
}

export function usePlayedSongsQuery(partyId: string) {
  return useSongsQueryGeneric(
    partyId,
    [where("played", "==", true), orderBy("numUpvotes", "desc")],
    "played"
  );
}

export function useMyUpvotesQuery(partyId: string, uid: string) {
  const myUpvotesRef = query(
    collectionGroup(firestore, "upvotes"),
    where("userId", "==", uid),
    where("partyId", "==", partyId)
  );

  return useFirestoreQuery(
    ["myUpvotes", uid, partyId],
    myUpvotesRef,
    { subscribe: true },
    {
      cacheTime: 0,
      select(snapshot): Set<string> {
        const songIds = new Set<string>();

        snapshot.docs.forEach((docSnapshot) => {
          if (docSnapshot.ref?.parent?.parent?.id) {
            const songId = docSnapshot.ref.parent.parent.id;
            songIds.add(songId);
          }
        });

        return songIds;
      },
    }
  );
}

interface TipsDict {
  [index: string]: number;
}

export function useMyTipsQuery(partyId: string, uid: string) {
  const tipsRef = query(
    collectionGroup(firestore, "tips"),
    where("user", "==", uid),
    where("partyId", "==", partyId)
  ) as CollectionReference<Tip>;

  return useFirestoreQuery(
    ["tips", partyId],
    tipsRef,
    { subscribe: true },
    {
      select(snapshot): TipsDict {
        const tipsDict: TipsDict = {};
        snapshot.docs.forEach((docSnapshot) => {
          if (!docSnapshot.ref.parent.parent) return;
          const songId = docSnapshot.ref.parent.parent.id;
          const tipAmount = docSnapshot.data().amount;
          if (tipsDict[songId]) {
            tipsDict[songId] += tipAmount;
          } else {
            tipsDict[songId] = tipAmount;
          }
        });
        return tipsDict;
      },
    }
  );
}

export const useSpotifySearchQuery = (queryText: string) =>
  useQuery<SongInfo[]>(
    ["songSearch", queryText],
    () =>
      getSpotifySearch({ query: queryText }).then((res: any) =>
        res.data.tracks.items.map((item: any) => ({
          artist: item.artists[0].name,
          name: item.name,
          id: item.id,
          imgUrl: item.album.images[0].url,
        }))
      ),
    {
      enabled: queryText.trim().length > 0,
      keepPreviousData: true,
    }
  );

export const useSpotifyTop50Query = () =>
  useQuery<SongInfo[]>(["spotifyTop50"], () =>
    getSpotifyTop50().then((res: any) =>
      res.data.map((item: any) => ({
        artist: item.track.artists[0].name,
        name: item.track.name,
        id: item.track.id,
        imgUrl: item.track.album.images[0].url,
      }))
    )
  );
