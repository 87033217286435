import * as React from "react";

export default function SingleUpSvg(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width={36}
      height={32}
      viewBox="0 0 37 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_1293_3167" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7035 12.1134L32.0024 26.0002L36.2451 21.57L18.7572 3.30908L18.0416 4.05631L17.9879 4.0002L0.5 22.2611L4.74264 26.6913L18.7035 12.1134Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7035 12.1134L32.0024 26.0002L36.2451 21.57L18.7572 3.30908L18.0416 4.05631L17.9879 4.0002L0.5 22.2611L4.74264 26.6913L18.7035 12.1134Z"
        fill="current"
      />
      <path
        d="M32.0024 26.0002L31.2802 26.6919L32.0024 27.446L32.7247 26.6919L32.0024 26.0002ZM18.7035 12.1134L19.4257 11.4217L18.7035 10.6675L17.9812 11.4217L18.7035 12.1134ZM36.2451 21.57L36.9673 22.2617L37.6297 21.57L36.9673 20.8784L36.2451 21.57ZM18.7572 3.30908L19.4794 2.61743L18.7572 1.86327L18.035 2.61743L18.7572 3.30908ZM18.0416 4.05631L17.3194 4.74796L18.0416 5.50212L18.7638 4.74796L18.0416 4.05631ZM17.9879 4.0002L18.7101 3.30855L17.9879 2.55439L17.2656 3.30855L17.9879 4.0002ZM0.5 22.2611L-0.222229 21.5695L-0.884602 22.2611L-0.222229 22.9528L0.5 22.2611ZM4.74264 26.6913L4.02041 27.383L4.74264 28.1371L5.46487 27.383L4.74264 26.6913ZM32.7247 25.3085L19.4257 11.4217L17.9812 12.805L31.2802 26.6919L32.7247 25.3085ZM35.5228 20.8784L31.2802 25.3085L32.7247 26.6919L36.9673 22.2617L35.5228 20.8784ZM18.035 4.00074L35.5228 22.2617L36.9673 20.8784L19.4794 2.61743L18.035 4.00074ZM18.7638 4.74796L19.4794 4.00074L18.035 2.61743L17.3194 3.36466L18.7638 4.74796ZM17.2656 4.69185L17.3194 4.74796L18.7638 3.36466L18.7101 3.30855L17.2656 4.69185ZM1.22223 22.9528L18.7101 4.69186L17.2656 3.30855L-0.222229 21.5695L1.22223 22.9528ZM5.46487 25.9997L1.22223 21.5695L-0.222229 22.9528L4.02041 27.383L5.46487 25.9997ZM17.9812 11.4217L4.02041 25.9997L5.46487 27.383L19.4257 12.805L17.9812 11.4217Z"
        fill="#2A3C44"
        fillOpacity="0.5"
        mask="url(#path-1-inside-1_1293_3167)"
      />
    </svg>
  );
}
