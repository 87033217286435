import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  navTo?: string;
};

const BackButton = ({ navTo }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className="back-button w-10 h-10"
      onClick={() => {
        navTo ? navigate(navTo) : navigate(-1);
      }}
    >
      <h2>{"<"}</h2>
    </div>
  );
};

export default BackButton;
