import React from "react";
import ReactLoading from "react-loading";

type Props = {
  size?: number;
};

function LoadingSpinner({ size }: Props) {
  return (
    <ReactLoading
      type="spin"
      color="#EFD36E"
      height={size ?? 100}
      width={size ?? 100}
    />
  );
}

export default LoadingSpinner;
