import * as React from "react";

export default function DoubleUpSvg(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width={36}
      height={32}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_1293_3175" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2035 17.7316L31.5024 31.0002L35.7451 26.7673L18.2572 9.31934L17.5416 10.0333L17.4879 9.97969L0 27.4276L4.24264 31.6606L18.2035 17.7316Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2035 17.7316L31.5024 31.0002L35.7451 26.7673L18.2572 9.31934L17.5416 10.0333L17.4879 9.97969L0 27.4276L4.24264 31.6606L18.2035 17.7316Z"
        fill="current"
      />
      <path
        d="M31.5024 31.0002L30.7961 31.7081L31.5024 32.4128L32.2087 31.7081L31.5024 31.0002ZM18.2035 17.7316L18.9098 17.0237L18.2035 16.319L17.4972 17.0237L18.2035 17.7316ZM35.7451 26.7673L36.4514 27.4752L37.1609 26.7673L36.4514 26.0594L35.7451 26.7673ZM18.2572 9.31934L18.9635 8.61142L18.2572 7.90674L17.5509 8.61142L18.2572 9.31934ZM17.5416 10.0333L16.8353 10.7412L17.5416 11.4459L18.2479 10.7412L17.5416 10.0333ZM17.4879 9.97969L18.1942 9.27177L17.4879 8.56709L16.7816 9.27177L17.4879 9.97969ZM0 27.4276L-0.706298 26.7197L-1.41583 27.4276L-0.706298 28.1355L0 27.4276ZM4.24264 31.6606L3.53634 32.3685L4.24264 33.0732L4.94894 32.3685L4.24264 31.6606ZM32.2087 30.2923L18.9098 17.0237L17.4972 18.4395L30.7961 31.7081L32.2087 30.2923ZM35.0388 26.0594L30.7961 30.2923L32.2087 31.7081L36.4514 27.4752L35.0388 26.0594ZM17.5509 10.0273L35.0388 27.4752L36.4514 26.0594L18.9635 8.61142L17.5509 10.0273ZM18.2479 10.7412L18.9635 10.0272L17.5509 8.61142L16.8353 9.32538L18.2479 10.7412ZM16.7816 10.6876L16.8353 10.7412L18.2479 9.32538L18.1942 9.27177L16.7816 10.6876ZM0.706298 28.1355L18.1942 10.6876L16.7816 9.27177L-0.706298 26.7197L0.706298 28.1355ZM4.94894 30.9527L0.706298 26.7197L-0.706298 28.1355L3.53634 32.3685L4.94894 30.9527ZM17.4972 17.0237L3.53634 30.9527L4.94894 32.3685L18.9098 18.4395L17.4972 17.0237Z"
        fill="#2A3C44"
        fillOpacity="0.5"
        mask="url(#path-1-inside-1_1293_3175)"
      />
      <mask id="path-3-inside-2_1293_3175" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2035 8.75214L31.5024 22.0207L35.7451 17.7878L18.2572 0.339844L17.5416 1.0538L17.4879 1.00019L0 18.4481L4.24264 22.6811L18.2035 8.75214Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2035 8.75214L31.5024 22.0207L35.7451 17.7878L18.2572 0.339844L17.5416 1.0538L17.4879 1.00019L0 18.4481L4.24264 22.6811L18.2035 8.75214Z"
        fill="current"
      />
      <path
        d="M31.5024 22.0207L30.7961 22.7287L31.5024 23.4333L32.2087 22.7287L31.5024 22.0207ZM18.2035 8.75214L18.9098 8.04423L18.2035 7.33954L17.4972 8.04423L18.2035 8.75214ZM35.7451 17.7878L36.4514 18.4957L37.1609 17.7878L36.4514 17.0799L35.7451 17.7878ZM18.2572 0.339844L18.9635 -0.368071L18.2572 -1.07276L17.5509 -0.36807L18.2572 0.339844ZM17.5416 1.0538L16.8353 1.76172L17.5416 2.4664L18.2479 1.76172L17.5416 1.0538ZM17.4879 1.00019L18.1942 0.292273L17.4879 -0.4124L16.7816 0.29228L17.4879 1.00019ZM0 18.4481L-0.706298 17.7402L-1.41583 18.4481L-0.706298 19.1561L0 18.4481ZM4.24264 22.6811L3.53634 23.389L4.24264 24.0937L4.94894 23.389L4.24264 22.6811ZM32.2087 21.3128L18.9098 8.04423L17.4972 9.46006L30.7961 22.7287L32.2087 21.3128ZM35.0388 17.0799L30.7961 21.3128L32.2087 22.7287L36.4514 18.4957L35.0388 17.0799ZM17.5509 1.04776L35.0388 18.4957L36.4514 17.0799L18.9635 -0.368071L17.5509 1.04776ZM18.2479 1.76172L18.9635 1.04776L17.5509 -0.36807L16.8353 0.34589L18.2479 1.76172ZM16.7816 1.70811L16.8353 1.76172L18.2479 0.345884L18.1942 0.292273L16.7816 1.70811ZM0.706298 19.1561L18.1942 1.70811L16.7816 0.29228L-0.706298 17.7402L0.706298 19.1561ZM4.94894 21.9732L0.706298 17.7402L-0.706298 19.1561L3.53634 23.389L4.94894 21.9732ZM17.4972 8.04423L3.53634 21.9732L4.94894 23.389L18.9098 9.46006L17.4972 8.04423Z"
        fill="#2A3C44"
        fillOpacity="0.5"
        mask="url(#path-3-inside-2_1293_3175)"
      />
    </svg>
  );
}
