import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo1080.png";
import BackButton from "./BackButton";

type Props = {
  leftButtonType: "back" | "home";
  leftButtonLink?: string;
  title: string;
  rightComponent?: React.ReactNode;
  centerTitle?: boolean;
  showHost?: boolean;
};

function Header({
  leftButtonType,
  leftButtonLink,
  title,
  rightComponent,
  centerTitle,
  showHost,
}: Props) {
  return (
    <div className="flex flex-row mx-5 pt-9 pb-6 items-center gap-3 overflow-hidden">
      {leftButtonType === "back" ? (
        <BackButton navTo={leftButtonLink} />
      ) : (
        <Link to="/" className="shrink-0">
          <img src={logo} className="rounded-lg w-10 h-10" alt="Amplifi Logo" />
        </Link>
      )}
      {/* flex-col is causing truncate to not work for some reason. */}
      <div className="grow flex flex-col">
        <h1 className={`text-2xl ${centerTitle ? "text-center" : ""}`}>
          {title}
        </h1>
        {showHost && (
          <p className="text-primary text-base text-center mt-[-4px]">
            Host View
          </p>
        )}
      </div>
      {rightComponent ? rightComponent : <div className="w-[40px]" />}
    </div>
  );
}

export default Header;
