import {
  collectionGroup,
  CollectionReference,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FullScreenLoad from "../../components/FullScreenLoad";
import SongCard from "../../components/Cards/SongCard";
import { SongRequestInfo, Tip } from "../../data/songs/types";

const db = getFirestore();

export default function PaymentConfirmation() {
  const { sessionId } = useParams();

  const [tipAmount, setTipAmount] = useState(0);
  const [songInfo, setSongInfo] = useState<SongRequestInfo>();

  useEffect(() => {
    async function fetchTipInfo() {
      const tipsCG = collectionGroup(db, "tips") as CollectionReference<Tip>;

      const querySnapshot = await getDocs(
        query(tipsCG, where("stripeSessionId", "==", sessionId))
      );

      if (
        querySnapshot.docs.length > 0 &&
        querySnapshot.docs[0].ref.parent.parent
      ) {
        setTipAmount(querySnapshot.docs[0].data().amount);
        const songReqDoc = await getDoc(
          querySnapshot.docs[0].ref.parent
            .parent as DocumentReference<SongRequestInfo>
        );
        setSongInfo(songReqDoc.data());
      }
    }

    fetchTipInfo();
  }, [sessionId]);

  if (!songInfo) {
    return <FullScreenLoad debugInfo="Loading payment confirmation" />;
  }

  return (
    <div className="flex flex-col items-center mt-12">
      <h1 className="text-2xl">🎊 Tip Confirmation 🎊</h1>
      <h1 className="mt-4 mb-9 font-semibold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-orange-300 to-yellow-300 text-orange-900">
        ${tipAmount}
      </h1>
      <div>
        <SongCard type="songOnly" songInfo={songInfo} />
      </div>
      <Link to="../" className="mt-4">
        <button className="btn btn-gradient">Back</button>
      </Link>
    </div>
  );
}
