import {
  PartialWithFieldValue,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { SongRequestInfo } from "./types";

export const songRequestConverter = {
  toFirestore: (data: PartialWithFieldValue<SongRequestInfo>) => ({
    ...data,
  }),
  fromFirestore: (snap: QueryDocumentSnapshot) =>
    ({
      id: snap.id,
      ...snap.data(),
    } as SongRequestInfo),
};
