import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ConfirmSongView from "./pages/party/ConfirmSongView";
import { getAnalytics, logEvent } from "firebase/analytics";
import PartyIndex from "./pages/party";
import MainRouteWrapper from "./components/MainRouteWrapper";
import Home from "./pages/home";
import CreateParty from "./pages/party/CreateParty";
import PaymentConfirmation from "./pages/party/PaymentConfirmation";
import { QueryClient, QueryClientProvider } from "react-query";
import LoginView from "./pages/login";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import EditParty from "./pages/party/EditParty";
import { UserContextProvider } from "./contexts/useUserContext";
import ProfilePage from "./pages/profile";
// import { ReactQueryDevtools } from "react-query/devtools";
import ProtectHostOnly from "./pages/misc/ProtectHostOnly";
import ProtectAuthedOnly from "./pages/misc/ProtectAuthedOnly";
import UserQRRedirect from "./pages/misc/UserQRRedirect";
import MainPartyView from "./pages/party/MainPartyView";
import SpotifyCallback from "./pages/profile/SpotifyCallback";
import { connectFunctionsEmulator } from "firebase/functions";
import { functions } from "./utils/firebase";

const analytics = getAnalytics();
// logEvent(analytics, "config", { debug_mode: true });

// if (true) {
//   connectFunctionsEmulator(functions, "localhost", 5002);
// }

const queryClient = new QueryClient();

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#f7cc45",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <ThemeProvider theme={darkTheme}>
        <UserContextProvider>
          <Router>
            <Routes>
              <Route path="" element={<MainRouteWrapper />}>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<LoginView />} />
                <Route
                  path="/createParty"
                  element={
                    <ProtectAuthedOnly>
                      <CreateParty />
                    </ProtectAuthedOnly>
                  }
                />
                <Route path="/party/:partyId" element={<PartyIndex />}>
                  <Route index element={<MainPartyView />} />
                  <Route
                    path="edit"
                    element={
                      <ProtectHostOnly>
                        <EditParty />
                      </ProtectHostOnly>
                    }
                  />
                  <Route path="confirm" element={<ConfirmSongView />} />
                  <Route path="boost" element={<ConfirmSongView boost />} />
                  <Route
                    path="txnConfirm/:sessionId"
                    element={<PaymentConfirmation />}
                  />
                </Route>
                <Route
                  path="/feedback"
                  element={() => {
                    console.log("Getting feedback");
                    logEvent(analytics, "feedback_click");
                    window.location.href =
                      "https://forms.gle/SKi6sUKs6cqDYgBW6";
                    return null;
                  }}
                />
                <Route
                  path="/me"
                  element={
                    <ProtectAuthedOnly>
                      <ProfilePage me />
                    </ProtectAuthedOnly>
                  }
                />
                <Route path="/uqr/:userId" element={<UserQRRedirect />} />
                <Route path="/u/:userId" element={<ProfilePage />} />
                <Route
                  path="/spotify-connect/callback"
                  element={<SpotifyCallback />}
                />
                <Route path="*" element={<div>404: Nothing found here</div>} />
              </Route>
            </Routes>
          </Router>
        </UserContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
