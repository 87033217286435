import React, { useState } from "react";
import FullScreenSearch from "./FullScreenSearch";
import { SongInfo } from "../../../data/songs/types";

export default function useFullScreenSearch(
  songPicked: (song: SongInfo) => void,
  title: string
) {
  const [searchOpen, setSearchOpen] = useState(false);

  function renderFullScreenSearch() {
    return (
      <FullScreenSearch
        searchCanceled={() => setSearchOpen(false)}
        songPicked={(song) => {
          songPicked(song);
          setSearchOpen(false);
        }}
        title={title}
      />
    );
  }

  const openSearch = () => setSearchOpen(true);

  return { openSearch, searchOpen, renderFullScreenSearch };
}
