import React from "react";
import { Outlet } from "react-router-dom";
import FullScreenLoad from "../../components/FullScreenLoad";
import { usePartyQuery } from "../../data/parties/queries";

function PartyIndex() {
  const { isLoading, isFound } = usePartyQuery();

  if (isLoading) {
    return <FullScreenLoad debugInfo="Checking if Party exists" />;
  } else if (isFound) {
    return <Outlet />;
  } else {
    return <div>No party found!</div>;
  }
}

export default PartyIndex;
