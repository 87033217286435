import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc, DocumentReference } from "firebase/firestore";
import React, { useContext, createContext } from "react";
import { User } from "../data/users/types";
import { auth, firestore } from "../utils/firebase";
import { useAuthUser } from "@react-query-firebase/auth";

const UserContext = createContext<null | false | User>(null);

type Props = {
  children: React.ReactNode;
};

export const UserContextProvider = ({ children }: Props) => {
  // const [accessToken, setAccessToken] = useState();
  const firebaseUser = useAuthUser(["firebaseUser"], auth);

  const userRef = doc(
    firestore,
    `users/${firebaseUser.data?.uid}`
  ) as DocumentReference<User>;
  const user = useFirestoreDocumentData<User>(
    ["user", firebaseUser.data?.uid],
    userRef,
    {},
    {
      enabled: !!firebaseUser.data?.uid,
    }
  );

  // Has to be a better way of doing this
  let value: null | false | User = null;
  if (!firebaseUser.isLoading && !user.isLoading) {
    value =
      user.data && firebaseUser.data
        ? {
            ...user.data,
            id: firebaseUser.data.uid,
          }
        : false;
  }
  // user.data && firebaseUser.data
  //   ? ;
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
