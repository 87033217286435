// Used for protecting a party's page to be only accessible to the host

import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../../contexts/useUserContext";
import { usePartyQuery } from "../../data/parties/queries";

type Props = {
  children: JSX.Element;
};

function ProtectHostOnly({ children }: Props) {
  const { data: party } = usePartyQuery();
  const user = useUserContext();

  if (user && user.id === party.hostId) {
    console.log("Passes the auth check");
    return children;
  } else {
    console.log("Doesn't pass the auth check");
    return <Navigate replace to="../" />;
  }
}

export default ProtectHostOnly;
