import { httpsCallable } from "firebase/functions";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/useUserContext";
import { functions } from "../../utils/firebase";

const code = new URLSearchParams(window.location.search).get("code");

const onCallbackFunc = httpsCallable(functions, "https-spotifyCallback");

function SpotifyCallback() {
  const navigate = useNavigate();
  const user = useUserContext();

  useEffect(() => {
    // This assumes a user exists, need to fix and secure
    onCallbackFunc({
      code,
      uid: user ? user.id : "dud",
      redirectHost: `${window.location.protocol}//${window.location.host}`,
    })
      .then(() => navigate("/"))
      .catch(() => console.error("Login BAD"));
    // axios
    //   .post(
    //     "http://localhost:5001/amplifi-d39a5/us-central1/api/spotify/callback",
    //     { code, uid: user ? user.id : "dud" } // This assumes a user exists, need to fix and secure
    //   )
    //   .then(() => navigate("/"))
    //   .catch(() => console.error("Login BAD"));
  }, [navigate, user]);

  return <div>SpotifyCallback</div>;
}

export default SpotifyCallback;
