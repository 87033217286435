import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUserContext } from "../../../contexts/useUserContext";

export interface PartyFormDetails {
  name: string;
  description: string;
  guestsCanAdd: boolean;
  acceptTips: boolean;
  endDateTimeString: string;
}

type Props = {
  actionText: string;
  onSubmit: (data: PartyFormDetails) => Promise<void>;
  defaultValues?: Partial<PartyFormDetails>;
  isProcessing?: boolean;
  isEdit?: boolean;
};

export function PartyForm({
  actionText,
  onSubmit,
  defaultValues,
  isProcessing,
  isEdit,
}: Props) {
  const {
    register,
    handleSubmit,
    watch,
    getFieldState,
    formState: { errors },
  } = useForm<PartyFormDetails>({ defaultValues });

  const user = useUserContext();

  const [edited, setEdited] = useState(false);

  useEffect(() => {
    const sub = watch(({ endDateTimeString }) => {
      console.log(endDateTimeString);
      setEdited(true);
    });
    return () => sub.unsubscribe();
  }, [watch, getFieldState]);

  if (!user) {
    return <div>Error. No user</div>;
  }

  console.log(defaultValues?.acceptTips);

  return (
    <form
      className="form-control w-full px-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <label className="label font-semibold">Party Name</label>
        <input
          className="input w-full input-primary"
          type="text"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <label className="label text-error">This field is required</label>
        )}
      </div>

      {/* <div className="">
        <label className="label font-semibold">Description</label>
        <input
          className="input w-full input-primary"
          type="text"
          {...register("description")}
        />
      </div> */}

      <div className="px-3">
        <label className="label cursor-pointer justify-start gap-3 text-base">
          <input
            className="checkbox checkbox-primary"
            type="checkbox"
            {...register("guestsCanAdd")}
          />
          <span className="label-text text-base">
            Allow Guests to Add Songs
          </span>
        </label>
      </div>

      {user.allowAcceptTips && (
        <>
          <div className="px-3">
            <label
              className={`label cursor-pointer justify-start gap-3 text-base${
                isEdit ? "text-slate-500" : ""
              }`}
            >
              <input
                className="checkbox checkbox-primary"
                type="checkbox"
                {...register("acceptTips")}
                disabled={isEdit}
              />
              <span
                className={`label-text text-base${
                  isEdit ? " text-gray-400" : ""
                }`}
              >
                Accept Tips
              </span>
            </label>
          </div>
          {/* <div className="w-full">
            <label className="label font-semibold">Party End</label>
            <input
              type="datetime-local"
              className="input input-primary"
              min={moment().format("YYYY-MM-DDTHH:mm")}
              {...register("endDateTimeString", {
                validate: {
                  minDate: (value) => {
                    return true;
                  },
                },
              })}
            />
            {errors.endDateTimeString && (
              <label className="label text-error">
                Can't set a date in the past
              </label>
            )}
          </div> */}
        </>
      )}

      <div className="flex justify-center mt-4">
        <button
          type="submit"
          className={`btn btn-lg ${edited ? "btn-gradient" : "btn-disabled"} ${
            isProcessing ? "loading" : ""
          }`}
          disabled={!edited}
        >
          {actionText}
        </button>
      </div>
    </form>
  );
}
