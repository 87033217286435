import React, { useState } from "react";
import SongCard from "../../../components/Cards/SongCard";
import { SongInfo } from "../../../data/songs/types";
import {
  useSpotifySearchQuery,
  useSpotifyTop50Query,
} from "../../../data/songs/queries";
import LoadingSpinner from "../../../components/LoadingSpinner";

interface Props {
  searchCanceled: () => void;
  songPicked: (track: SongInfo) => void;
  title: string;
}

function FullScreenSearch({
  searchCanceled,
  songPicked,
  title,
}: Props): JSX.Element {
  // searchCanceled -> Runs whenever the cancel button is clicked
  // songPicked -> Method, takes in songDetails as an argument
  const [queryText, setQueryText] = useState("");

  // useQueryClient();
  const songSearchQuery = useSpotifySearchQuery(queryText);
  const spotifyTop50Query = useSpotifyTop50Query();

  console.log(spotifyTop50Query.data);

  return (
    <div>
      <div className="flex flex-row w-full pt-8 pb-4 px-4 gap-4 items-center">
        <input
          type="text"
          autoFocus
          placeholder="Type here"
          className="input input-bordered input-primary grow text-base"
          value={queryText}
          onChange={(e) => setQueryText(e.target.value)}
        />
        <h3 className="text-error" onClick={searchCanceled}>
          Cancel
        </h3>
      </div>
      {(songSearchQuery.isFetching || spotifyTop50Query.isFetching) && (
        <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-90 p-8 bg-gray-900 rounded-xl">
          <LoadingSpinner size={60} />
        </div>
      )}
      {queryText === "" && <h1 className="ml-4 my-2 text-lg">Top Songs</h1>}
      {(queryText === "" ? spotifyTop50Query : songSearchQuery).data?.map(
        (track) => (
          <SongCard
            key={track.id}
            songInfo={track}
            type="songOnly"
            onClick={songPicked}
          />
        )
      )}
    </div>
  );
}

export default FullScreenSearch;
