import {
  doc,
  DocumentReference,
  getDoc,
  increment,
  serverTimestamp,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { omit } from "lodash";
import { firestore } from "../../utils/firebase";
import { SongInfo, SongRequestInfo } from "./types";

export async function addSongOrUpvote(
  songInfo: SongInfo,
  partyId: string | undefined,
  upvotedBy: string // Put this into a hook later on. Temporary solution
) {
  const songRef = doc(
    firestore,
    `parties/${partyId}/songRequests/${songInfo.id}`
  ) as DocumentReference<Omit<SongRequestInfo, "id">>;
  const docSnapshot = await getDoc(songRef);

  if (docSnapshot.exists()) {
    // await updateDoc(songRef, {
    //   upvotes: arrayUnion(upvotedBy),
    // });
    await toggleUpvote(true, partyId ?? "", songInfo.id, upvotedBy);
  } else {
    await setDoc(songRef, {
      ...omit(songInfo, ["id"]),
      upvotes: [upvotedBy], // To be removed later
      totalTips: 0,
      played: false,
      numUpvotes: 1,
    });
    const upvoteDoc = doc(songRef, `upvotes/${upvotedBy}`);
    await setDoc(upvoteDoc, {
      createTime: serverTimestamp(),
      partyId,
      userId: upvotedBy,
    });
  }
}

export async function toggleUpvote(
  upvote: boolean,
  partyId: string,
  songId: string,
  userId: string
) {
  const songRef = doc(firestore, `parties/${partyId}/songRequests/${songId}`);
  const upvoteRef = doc(songRef, `upvotes/${userId}`);

  // Use a transaction to ensure that the field is incremented correctly
  const batch = writeBatch(firestore);

  batch.update(songRef, {
    numUpvotes: increment(upvote ? 1 : -1),
  });

  if (upvote) {
    batch.set(upvoteRef, {
      createTime: serverTimestamp(),
      userId,
      partyId,
    });
  } else {
    batch.delete(upvoteRef);
  }

  await batch.commit();
}
