import React from "react";

type Props = {
  children: React.ReactNode;
};

function SectionHeader({ children }: Props) {
  return <h1 className="font-light ml-4 text-2xl mt-4 mb-2">{children}</h1>;
}

export default SectionHeader;
