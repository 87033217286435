import React from "react";
import { FiMoreVertical } from "react-icons/fi";
import { FiMusic } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/useUserContext";
import {
  useDeletePartyMutation,
  useToggleActivePartyMutation,
} from "../../data/parties/mutations";
import { User } from "../../data/users/types";
import CardBase from "./CardBase";

function timeSince(date: Date) {
  var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + "mo";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "m";
  }
  return Math.floor(seconds) + "s";
}

type PartyCardProps = {
  name: string;
  partyId: string;
  host: string;
  img: string;
  lastUpdated: Date;
  numSongs: number;
  partyActive?: boolean;
  onClick?: () => void;
  showOptions?: boolean;
};

function PartyCard({
  name,
  partyId,
  host,
  img,
  lastUpdated,
  numSongs,
  partyActive,
  onClick,
  showOptions,
}: PartyCardProps) {
  const navigate = useNavigate();
  const user = useUserContext() as User;

  const { setActive, setInactive } = useToggleActivePartyMutation(
    partyId,
    // meQuery.data.id
    user.id
  );
  const { mutate: deleteParty } = useDeletePartyMutation(partyId);

  const handleToggleActive = () => {
    // NOT GOOD: MAKE SURE THAT THE USER IS LOADED
    // if (!meQuery.isSuccess) return;

    if (partyActive) {
      setInactive();
    } else {
      setActive();
    }
  };

  const handleEdit = () => {
    navigate(`/party/${partyId}/edit`);
  };
  const handleDelete = () => {
    deleteParty();
  };

  return (
    <>
      <CardBase
        leftImg={img}
        title={name}
        subtitle={host}
        onClick={onClick}
        rightInnerComponent={
          <div className="flex flex-col items-end">
            <p className="flex items-center gap-1">
              {numSongs}{" "}
              <span className="inline-block">
                <FiMusic />
              </span>
            </p>
            <p className="opacity-50 font-light text-xs truncate">
              {timeSince(lastUpdated)} ago
            </p>
          </div>
        }
        rightOuterComponent={
          showOptions && (
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost p-2 m-0">
                <FiMoreVertical />
              </label>
              <ul className="dropdown-content menu p-2 shadow bg-base-300 rounded-2xl w-52">
                <li>
                  <button
                    onClick={handleToggleActive}
                    className="p-2 active:bg-primary rounded-2xl z-10"
                  >
                    {partyActive ? "Unpin" : "Pin"}
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleEdit}
                    className="p-2 active:bg-primary rounded-2xl z-10"
                  >
                    Edit
                  </button>
                </li>
                <li>
                  <label
                    htmlFor={`delete-party-modal-${partyId}`}
                    className="p-2 active:bg-primary rounded-2xl z-10 modal-open"
                  >
                    Delete
                  </label>
                </li>
              </ul>
            </div>
          )
        }
      />

      <input
        type="checkbox"
        id={`delete-party-modal-${partyId}`}
        className="modal-toggle"
      />
      <label
        htmlFor={`delete-party-modal-${partyId}`}
        className="modal cursor-pointer"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="text-lg font-bold">Confirm delete?</h3>
          <p className="py-4">
            This will delete {name}. This action cannot be undone.
          </p>
          <div className="flex flex-row justify-end">
            <div className="modal-action">
              <label className="btn" htmlFor={`delete-party-modal-${partyId}`}>
                Disagree
              </label>
            </div>
            <div className="modal-action">
              <label
                className="btn"
                htmlFor={`delete-party-modal-${partyId}`}
                onClick={handleDelete}
              >
                Agree
              </label>
            </div>
          </div>
        </label>
      </label>
    </>
  );
}

export default PartyCard;
