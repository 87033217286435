/*
For now we use this screen to edit a party as well
*/

import React from "react";
import { useNavigate } from "react-router-dom";
import { PartyFormDetails, PartyForm } from "./components/PartyForm";
import { useUserContext } from "../../contexts/useUserContext";
import { useCreatePartyMutation } from "../../data/parties/mutations";
import Header from "../../components/Header";
import moment from "moment";

export default function CreateParty() {
  const navigate = useNavigate();
  const user = useUserContext();
  const {
    mutateAsync: createDBParty,
    isLoading,
    isSuccess,
  } = useCreatePartyMutation();

  async function onSubmit(data: PartyFormDetails) {
    if (!user) {
      console.error("There should've been a user here when creating a party");
      return;
    }

    // TODO: Fix data any type
    const partyId = await createDBParty({
      partyName: data.name,
      hostId: user.id,
      hostName: user.displayName || "",
      hostImg:
        user.photoUrl ||
        "https://media.istockphoto.com/vectors/avatar-5-vector-id1131164548?k=20&m=1131164548&s=170667a&w=0&h=VlwTJ3LpA8Pjzk9u8XYgkII0Vrvrb07e67cHALFX_aY=",
      guestsCanAdd: data.guestsCanAdd,
      tipsEnabled: !!data.acceptTips,
    });
    console.log("Created!", partyId);
    navigate(`/party/${partyId}/`);
  }

  return (
    <div>
      <Header leftButtonType="back" title="🎉 Create Party" />
      <PartyForm
        actionText="Create Party"
        onSubmit={onSubmit}
        defaultValues={{
          guestsCanAdd: true,
          endDateTimeString: moment().add(1, "day").format("YYYY-MM-DDTHH:mm"),
        }}
        isProcessing={isLoading || isSuccess}
      />
    </div>
  );
}
