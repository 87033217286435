import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SongCard from "../../components/Cards/SongCard";
import { getAnalytics, logEvent } from "firebase/analytics";
import CurrencyInput from "react-currency-input-field";
import getSignature from "../../hooks/useSignature";
import axios from "axios";
import { useUserContext } from "../../contexts/useUserContext";
import { usePartyQuery } from "../../data/parties/queries";
import { addSongOrUpvote } from "../../data/songs/mutations";
import Header from "../../components/Header";

const MIN_TIP_AMOUNT = 2;

const PAYMENT_URL =
  "https://us-central1-amplifi-d39a5.cloudfunctions.net/api/create-sr-checkout-session";

const analytics = getAnalytics();

interface Props {
  boost?: boolean;
}

function ConfirmSongView({ boost }: Props) {
  /* Takes in 4 search parameters, id (song ID), name, artist, imgUrl
   */
  const { data: party } = usePartyQuery();
  const partyId = party.id;
  const [tip, setTip] = useState(boost ? 0 : 0);
  const [showError, setShowError] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const user = useUserContext();

  const signature = user ? user.id : getSignature();

  const searchParams = useSearchParams()[0];
  const navigate = useNavigate();

  const songInfo = {
    id: searchParams.get("id") as string,
    name: searchParams.get("name") as string,
    artist: searchParams.get("artist") as string,
    imgUrl: searchParams.get("imgUrl") as string,
  };

  function logAnalyticsOnSubmit() {
    if (boost)
      logEvent(analytics, "song_tipped", { signature, party_id: partyId });
    else if (tip > 0)
      logEvent(analytics, "paid_song_request_added", {
        signature,
        party_id: partyId,
      });
    else
      logEvent(analytics, "song_request_added", {
        signature,
        party_id: partyId,
      });
  }

  async function onSubmit() {
    if (
      (boost && tip < MIN_TIP_AMOUNT) ||
      (!boost && tip > 0 && tip < MIN_TIP_AMOUNT)
    ) {
      // If it's supposed to be a boost but no tip has been entered, exit
      setShowError(true);
      return;
    }

    setLoadingPayment(true);
    await addSongOrUpvote(songInfo, partyId, signature);
    logAnalyticsOnSubmit();

    console.log(party.name);

    // Then add a tip if there is
    if (tip >= MIN_TIP_AMOUNT) {
      const response = await axios.post(PAYMENT_URL, {
        tipAmount: tip,
        partyId,
        partyName: party.name,
        userSignature: signature,
        cancelRedirect: window.location.href,
        redirectAfterPay: `${window.location.origin}/party/${partyId}/txnConfirm/`,
        songInfo,
      });

      window.location.href = response.data.redirectUrl;
    } else {
      navigate("../");
    }
    setLoadingPayment(false);
  }

  const disable = boost
    ? tip < MIN_TIP_AMOUNT
    : tip > 0 && tip < MIN_TIP_AMOUNT;

  return (
    <div>
      <Header
        leftButtonType="back"
        title="Add a Tip"
        leftButtonLink={`/party/${partyId}`}
      />
      <div className="px-5 mb-4">
        <SongCard songInfo={songInfo} type="songOnly" />
      </div>
      <form className="form-control w-full px-5">
        <label className="label font-semibold">Tip Amount</label>
        <CurrencyInput
          id="tipInput"
          name="input-name"
          className="input w-full input-primary"
          prefix="$"
          placeholder="Input a tip if you want"
          value={tip}
          defaultValue={0}
          decimalsLimit={0}
          decimalScale={0}
          onValueChange={(value, name) => setTip(value ? parseInt(value) : 0)}
        />
        {showError && (
          <label className="label text-error">
            Error: Minimum tip amount is $2
          </label>
        )}

        <div className="flex justify-center mt-4">
          <button
            type="button"
            className={`btn ${disable ? "btn-disable" : "btn-gradient"} ${
              loadingPayment ? "loading" : ""
            }`}
            onClick={onSubmit}
          >
            {boost || tip !== 0 ? "Pay" : "Confirm"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ConfirmSongView;
