import { Dialog, DialogContent } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import React, { useState } from "react";
import styled from "styled-components";

type Props = {
  renderButton: React.ReactNode;
  title?: string;
  url: string;
};

const Title = styled.p`
  font-size: 1.4em;
  width: 100%;
  text-align: center;
  margin-bottom: 0.6em;
  font-weight: 600;
`;

function QRModal({ renderButton, title, url }: Props) {
  const [showQR, setShowQR] = useState(false);

  const handleOpen = () => setShowQR(true);
  const handleClose = () => setShowQR(false);

  return (
    <>
      <Dialog open={showQR} onClose={handleClose}>
        <DialogContent>
          {title && <Title>{title}</Title>}
          <QRCodeSVG
            value={url}
            size={256}
            bgColor="#383838"
            level="L"
            fgColor="white"
          />
        </DialogContent>
      </Dialog>
      <div onClick={handleOpen}>{renderButton}</div>
    </>
  );
}

export default QRModal;
