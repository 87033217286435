import {
  useFirestoreDocumentDeletion,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  PartialWithFieldValue,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useMutation, useQueryClient } from "react-query";
import { useUserContext } from "../../contexts/useUserContext";
import { firestore } from "../../utils/firebase";
import { partyConverter } from "./converters";
import { Party } from "./types";

const partyCol = collection(firestore, "parties").withConverter(partyConverter);

// Closes all active parties of a particular host
const closeActiveParties = async (hostId: string) => {
  const activePartiesQuery = query(
    partyCol,
    where("host", "==", hostId),
    where("active", "==", true)
  );

  const querySnapshot = await getDocs(activePartiesQuery);
  querySnapshot.forEach((doc) => {
    console.log(doc.data());
    updateDoc(doc.ref, { active: false, lastClosed: new Date() });
  });
};

const generatePinForNewParty = async () => {
  let pin = Math.floor(1000 + Math.random() * 9000);
  let found = false;
  for (let i = 0; i < 10; i++) {
    const q = query(partyCol, where("pin", "==", pin));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length === 0) {
      found = true;
      break;
    }
    pin = Math.floor(1000 + Math.random() * 9000);
  }
  if (!found) {
    throw new Error(
      "failed to generate a PIN. This function needs better implementation"
    );
  }
  return pin;
};

// Creates a party, then returns the ID as a string
export const useCreatePartyMutation = () =>
  useMutation(
    async ({
      partyName,
      hostId,
      hostName,
      hostImg,
      guestsCanAdd,
      tipsEnabled,
    }: {
      partyName: string;
      hostId: string;
      hostName: string;
      hostImg: string;
      guestsCanAdd: boolean;
      tipsEnabled: boolean;
    }) => {
      const pin = await generatePinForNewParty();
      await closeActiveParties(hostId);

      const doc = await addDoc<PartialWithFieldValue<Party>>(partyCol, {
        name: partyName,
        hostId,
        hostName,
        hostImg,
        active: true,
        tipsEnabled,
        guestsCanAdd,
        pin,
        createdAt: new Date(),
        lastOpened: new Date(),
        lastUpdated: new Date(),
        numSongs: 0,
        topSong: null,
      });
      return doc.id;
    }
  );

export const useDeletePartyMutation = (partyId: string) => {
  const queryClient = useQueryClient();
  const user = useUserContext();

  return useFirestoreDocumentDeletion(doc(partyCol, partyId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["parties", user ? user.id : null]);
    },
  });
};

export const useToggleActivePartyMutation = (
  partyId: string,
  hostId: string
) => {
  const queryClient = useQueryClient();
  const user = useUserContext();
  // Maybe we can think about how we want to store the active party
  // Store on the user object instead? For easier querying
  const { mutateAsync } = useFirestoreDocumentMutation<
    PartialWithFieldValue<Party>
  >(
    doc(partyCol, partyId),
    {
      merge: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parties", user ? user.id : null]);
      },
    }
  );

  return {
    setActive: async () => {
      await closeActiveParties(hostId);
      return mutateAsync({ active: true, lastOpened: new Date() });
    },
    setInactive: async () => {
      await closeActiveParties(hostId);
      return mutateAsync({ active: false, lastOpened: new Date() });
    },
  };
};
