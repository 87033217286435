import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import { doc, DocumentReference } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PartyForm, PartyFormDetails } from "./components/PartyForm";
import { firestore } from "../../utils/firebase";
import { Party } from "../../data/parties/types";
import Header from "../../components/Header";

function useClosePartyAlertDialog(
  title: string,
  description: string,
  onYes: () => void
) {
  const [open, setOpen] = useState(false);

  function openAlertDialog() {
    setOpen(true);
  }

  function closeAlertDialog() {
    setOpen(false);
  }

  function ClosePartyAlertDialog() {
    return (
      <Dialog
        open={open}
        onClose={closeAlertDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlertDialog}>Disagree</Button>
          <Button onClick={onYes} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return { ClosePartyAlertDialog, openAlertDialog, closeAlertDialog };
}

function EditParty() {
  const { partyId } = useParams();
  const navigate = useNavigate();
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);

  const partyDoc = doc(
    firestore,
    `parties/${partyId}`
  ) as DocumentReference<Party>;

  const partyQuery = useFirestoreDocumentData(["parties", partyId], partyDoc);
  const updatePartyMutation = useFirestoreDocumentMutation<Partial<Party>>(
    partyDoc,
    {
      merge: true,
    }
  );

  async function onClose() {
    console.log("Party closed");
    closeAlertDialog();
    await updatePartyMutation.mutateAsync({ active: false });
    navigate("/me");
  }

  const { ClosePartyAlertDialog, closeAlertDialog } = useClosePartyAlertDialog(
    "Close Party?",
    `This will close ${partyQuery.data?.name}`,
    onClose
  );

  async function onSubmit(data: PartyFormDetails) {
    // TODO: Fix data any type
    console.log("Edited!");

    await updatePartyMutation.mutateAsync(data);
    partyQuery.refetch();
    setShowSaveConfirm(true);

    // navigate(`/party/${partyId}/dj`);
  }

  if (!partyQuery.data) {
    return <div>Loading party data</div>;
  }

  return (
    <div>
      <ClosePartyAlertDialog />
      <Header leftButtonType="back" title="🎉 Edit Party" />

      <Collapse in={showSaveConfirm}>
        <Alert sx={{ width: "100%" }} onClose={() => setShowSaveConfirm(false)}>
          Save Successful
        </Alert>
      </Collapse>
      <PartyForm
        isEdit
        actionText="Save Changes"
        onSubmit={onSubmit}
        defaultValues={{
          name: partyQuery.data.name,
          description: "Testing",
          guestsCanAdd: partyQuery.data.guestsCanAdd,
          acceptTips: partyQuery.data.tipsEnabled,
        }}
        isProcessing={updatePartyMutation.isLoading}
      />
      {/* {partyQuery.data.active && (
        <div className="flex justify-center mt-4">
          <button
            className="btn btn-error btn-outline btn-sm"
            onClick={openAlertDialog}
          >
            Close Party
          </button>
        </div>
      )} */}
    </div>
  );
}

export default EditParty;
