import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { doc, updateDoc } from "firebase/firestore";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import SingleUpSvg from "../svgs/SingleUpSvg";
import DoubleUpSvg from "../svgs/DoubleUpSvg";
import getSignature from "../../hooks/useSignature";
import { SongInfo, SongRequestInfo } from "../../data/songs/types";
import { firestore, analytics } from "../../utils/firebase";
import { useUserContext } from "../../contexts/useUserContext";
import { toggleUpvote } from "../../data/songs/mutations";
import CardBase from "./CardBase";
import { FiMoreVertical } from "react-icons/fi";

function UpvoteButton({
  totalUpvotes,
  upvoted,
  onUpvoteClick,
}: {
  totalUpvotes: number;
  upvoted: boolean;
  onUpvoteClick: () => void;
}) {
  return (
    <div
      className="flex flex-col items-center text-center"
      onClick={onUpvoteClick}
    >
      <SingleUpSvg
        style={{
          fill: upvoted ? "#FFDD2A" : "white",
        }}
      />
      <h1 className="font-bold text-lg my-[-5px]">{totalUpvotes}</h1>
      <p className="font-light text-xs">Upvotes</p>
    </div>
  );
}

function TipButton({
  userTipAmount,
  goToBoost,
}: {
  userTipAmount: null | false | number | undefined;
  goToBoost: () => void;
}) {
  return (
    <div className="flex flex-col items-center text-center" onClick={goToBoost}>
      <DoubleUpSvg
        style={{
          fill: userTipAmount ? "#FFDD2A" : "white",
        }}
      />
      <h1 className="font-bold text-lg my-[-5px]">
        {userTipAmount ? `$${userTipAmount}` : "$"}
      </h1>
      <p className="font-light text-xs truncate">
        {userTipAmount ? "My Tip" : "Add Tip"}
      </p>
    </div>
  );
}

interface CommonProps {
  // songInfo: SongRequestInfo;
  // type: "dj" | "actions" | "songOnly" | "upvoteOnly";
  userTipAmount?: number;
  upvoted?: boolean;
}

type SongRequestProps =
  | {
      type: "songOnly";
      songInfo: SongInfo;
      onClick?: null | ((songInfo: SongInfo) => void);
    }
  | {
      type: "dj" | "actions" | "upvoteOnly";
      songInfo: SongRequestInfo;
      onClick?: null | ((songInfo: SongRequestInfo) => void);
    };

type Props = CommonProps & SongRequestProps;

function SongCard({ songInfo, type, onClick, userTipAmount, upvoted }: Props) {
  // onClick is a function that takes the songInfo as parameters
  const navigate = useNavigate();
  const { partyId } = useParams();

  // Use the browser signature if not logged in. Otherwise, use the user ID
  const user = useUserContext();
  const signature = user ? user.id : getSignature();

  const { id, name, artist, imgUrl, played, totalTips, numUpvotes } =
    songInfo as SongRequestInfo;

  const songRef = doc(firestore, `parties/${partyId}/songRequests/${id}`);

  const totalUpvotes = numUpvotes;
  // const totalUpvotes = upvotes?.length || 0;

  // const [upvoted, setUpvoted] = useState(upvotes?.includes(signature));
  const [playedState, setPlayedState] = useState(played);
  const [showDJActions, setShowDJActions] = useState(false); // Shrink to show mark as played etc. Only for DJs

  function handleUpvoteClick() {
    console.log("upvote clicked");
    // if (upvoted) {
    //   updateDoc(songRef, {
    //     upvotes: arrayRemove(signature),
    //   });
    //   logEvent(analytics, "upvote_canceled", {
    //     signature,
    //     party_id: partyId,
    //     song_id: id,
    //   });
    // } else {
    //   updateDoc(songRef, {
    //     upvotes: arrayUnion(signature),
    //   });
    //   logEvent(analytics, "upvote_clicked", {
    //     signature,
    //     party_id: partyId,
    //     song_id: id,
    //   });
    // }

    toggleUpvote(!upvoted, partyId ?? "", id, signature);
    // setUpvoted(!upvoted);
  }

  function togglePlayed() {
    logEvent(analytics, playedState ? "dj_unmark_played" : "dj_mark_played");
    updateDoc(songRef, {
      played: !playedState,
    });
    setPlayedState(!playedState);
    setShowDJActions(false);
  }

  const goToBoost = () => {
    navigate({
      pathname: "boost",
      search: `?${createSearchParams({
        id,
        name,
        artist,
        imgUrl,
      })}`,
    });
  };

  function renderRightSide() {
    if (played) {
      return (
        <p
          style={{
            textAlign: "right",
          }}
        >
          Played ({totalUpvotes})
          {type === "dj" && totalTips > 0 ? ` $${totalTips}` : ""}
        </p>
      );
    } else if (type === "actions") {
      return (
        <>
          <UpvoteButton
            totalUpvotes={totalUpvotes}
            upvoted={!!upvoted}
            onUpvoteClick={handleUpvoteClick}
          />
          <TipButton userTipAmount={userTipAmount} goToBoost={goToBoost} />
        </>
      );
    } else if (type === "upvoteOnly") {
      return (
        <UpvoteButton
          totalUpvotes={totalUpvotes}
          upvoted={!!upvoted}
          onUpvoteClick={handleUpvoteClick}
        />
      );
    } else if (type === "dj") {
      return (
        <div className="dj-card-right-info">
          <h1>{totalTips > 0 ? `$${totalTips}` : totalUpvotes}</h1>
          {totalTips > 0 && <h2>{totalUpvotes}</h2>}
        </div>
      );
    } else return null;
  }

  const getOnCardClick = () => {
    if (onClick) {
      return () => onClick(songInfo as SongRequestInfo);
    } else if (type === "dj") {
      return () => setShowDJActions(!showDJActions);
    }
    return undefined;
  };

  return (
    <CardBase
      leftImg={imgUrl}
      title={name}
      onClick={getOnCardClick()}
      subtitle={artist}
      tipInfo={totalTips > 0 ? `$${totalTips} Tipped` : undefined}
      rightInnerComponent={renderRightSide()}
      // rightOuterComponent={
      //   showDJActions && (
      //     <div className="flex justify-center items-center mx-2">
      //       <button className="btn btn-outline btn-sm" onClick={togglePlayed}>
      //         {played ? "Unmark played" : "Mark played"}
      //       </button>
      //     </div>
      //   )
      // }
      rightOuterComponent={
        type === "dj" && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="dropdown dropdown-end"
          >
            <label tabIndex={0} className="btn btn-ghost p-2 m-0">
              <FiMoreVertical />
            </label>
            <ul className="dropdown-content menu p-2 shadow bg-base-300 rounded-2xl w-52">
              <li>
                <button
                  onClick={togglePlayed}
                  className="p-2 active:bg-primary rounded-2xl z-10"
                >
                  {playedState ? "Unmark played" : "Mark played"}
                </button>
              </li>
              <li>
                <button
                  // onClick={handleEdit}
                  className="p-2 active:bg-primary rounded-2xl z-10"
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        )
      }
    />
  );
}

export default SongCard;
