import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const SHOW_DEBUG = false;

type Props = {
  debugInfo?: string;
};

// Displays a full screen loading screen with a message if debug enabled
function FullScreenLoad({ debugInfo }: Props) {
  return (
    <div className="flex h-screen">
      <div className="m-auto flex flex-col items-center gap-4">
        <LoadingSpinner />
        {SHOW_DEBUG && debugInfo && <p className="text-primary">{debugInfo}</p>}
      </div>
    </div>
  );
}

export default FullScreenLoad;
