import React from "react";
import styled from "styled-components";
import { FaQrcode } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar } from "@mui/material";
import QRModal from "./QRModal";
import { useNavigate } from "react-router-dom";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc, DocumentReference } from "firebase/firestore";
import { firestore } from "../utils/firebase";
import { User } from "../data/users/types";

const HostName = styled.p`
  text-align: right;
  font-weight: 600;
  color: white;
  margin-right: 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px; /* Don't hardcode this */
`;

export const HeaderActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2em;
  box-sizing: border-box;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1.5em;
`;

export const HeaderActionShare = ({
  url,
  title,
}: {
  url: string;
  title: string;
}) => {
  const handleShare = () => {
    console.log("Share button clicked");
    if (navigator.share) {
      navigator
        .share({
          title: "Amplifi",
          text: url,
        })
        .then(() => console.log("Successfully shared"));
    }
  };

  return (
    <div className="flex flex-row items-center" onClick={handleShare}>
      <ShareIcon color="primary" />
      <p className="ml-2 text-sm">{title}</p>
    </div>
  );
};

export const HeaderActionQRCode = ({
  title,
  QRTitle,
  url,
}: {
  title: string;
  QRTitle: string;
  url: string;
}) => {
  return (
    <QRModal
      renderButton={
        <div className="flex flex-row items-center">
          <FaQrcode className="w-6 h-6" color="primary" />
          <p className="ml-2 text-sm">{title}</p>
        </div>
      }
      title={QRTitle}
      url={url}
    />
  );
};

export const HeaderActionEdit = () => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`edit`);
  };
  return (
    <div className="flex flex-row items-center" onClick={handleEdit}>
      <EditIcon color="primary" />
      <p className="ml-2 text-sm">Edit</p>
    </div>
  );
};

export const HeaderActionSpotify = () => {
  return (
    <div className="flex flex-row items-center">
      <FiShare className="w-6 h-6" color="primary" />
      <p className="ml-2 text-sm">View on Spotify</p>
    </div>
  );
};

export const HeaderActionHost = ({ hostId }: { hostId: string }) => {
  const navigate = useNavigate();
  const hostRef = doc(firestore, `users/${hostId}`) as DocumentReference<User>;
  const host = useFirestoreDocumentData(["user", hostId], hostRef);

  const handleHostClick = () => {
    navigate(`/u/${hostId}`);
  };

  return (
    <div className="flex flex-row items-center" onClick={handleHostClick}>
      <HostName>{host.data?.displayName}</HostName>
      <Avatar
        style={{
          width: "1.5em",
          height: "1.5em",
        }}
        src={host.data?.photoUrl ?? ""}
      />
    </div>
  );
};
