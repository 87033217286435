import { getAnalytics, logEvent } from "firebase/analytics";
import React from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import {
  useMyTipsQuery,
  useNormalSongsQuery,
  useTippedSongsQuery,
  usePlayedSongsQuery,
  useMyUpvotesQuery,
} from "../../data/songs/queries";
import { addSongOrUpvote } from "../../data/songs/mutations";
import SongCard from "../../components/Cards/SongCard";
import getSignature from "../../hooks/useSignature";
import { SongInfo, SongRequestInfo } from "../../data/songs/types";
import useFullScreenSearch from "./components/useFullScreenSearch";
import { useUserContext } from "../../contexts/useUserContext";
import PartyHeaderActions from "./components/PartyHeaderActions";
import { usePartyQuery } from "../../data/parties/queries";
// import { httpsCallable } from "firebase/functions";
// import { functions } from "../../utils/firebase";
// import { useSpotifyPlayerSync } from "../../data/spotifySync";
import Header from "../../components/Header";
import SectionHeader from "../../components/SectionHeader";
import FullScreenLoad from "../../components/FullScreenLoad";
import { FiSettings } from "react-icons/fi";

const analytics = getAnalytics();
// const initSpotifyAutoplay = httpsCallable(
//   functions,
//   "https-initSpotifyAutoplay"
// );

function MainPartyView() {
  const navigate = useNavigate();
  const user = useUserContext();
  const {
    data: {
      name,
      tipsEnabled,
      guestsCanAdd,
      hostId,
      id: partyId,
      // spotifyPlaylistId,
      hostImg,
    },
  } = usePartyQuery();

  const signature = user ? user.id : getSignature();

  const isHost = Boolean(user && user.id === hostId);
  // const isHost = true;
  // const isSpotifyConnected = isHost && user ? user.isSpotifyConnected : false;

  // const {
  //   // currentArtist,
  //   // isConnected,
  //   // currentTrackName,
  //   // isPlaying,
  //   // playlistUri,
  // } = useSpotifyPlayerSync(isSpotifyConnected);

  const normalSongsQuery = useNormalSongsQuery(partyId);
  const tippedSongsQuery = useTippedSongsQuery(partyId);
  const playedSongsQuery = usePlayedSongsQuery(partyId);

  const myUpvotesQuery = useMyUpvotesQuery(partyId, signature);
  const myTipsQuery = useMyTipsQuery(partyId, signature);

  // function playOnSpotify() {
  //   console.log("Played");
  //   initSpotifyAutoplay({ partyId }).then((data) =>
  //     console.log("Successfully called", data)
  //   );
  // }

  async function onSRSearchComplete(songInfo: SongInfo) {
    if (tipsEnabled && !isHost) {
      // This feature is for no tipping
      const { id, name, artist, imgUrl } = songInfo;
      navigate({
        pathname: "../confirm",
        search: `?${createSearchParams({ id, name, artist, imgUrl })}`,
      });
    } else {
      addSongOrUpvote(songInfo, partyId, signature);
    }
  }

  const srSearch = useFullScreenSearch(onSRSearchComplete, "Add a song");

  function addSongClicked() {
    logEvent(analytics, "guest_add_song_btn_click", {
      signature,
      party_id: partyId,
    });
    srSearch.openSearch();
  }

  if (srSearch.searchOpen) {
    return srSearch.renderFullScreenSearch();
  }

  if (
    !myUpvotesQuery.data ||
    !myTipsQuery.data ||
    !normalSongsQuery.data ||
    !playedSongsQuery.data ||
    !tippedSongsQuery.data
  ) {
    return <FullScreenLoad debugInfo="Loading song requests" />;
  }

  const renderSection = (songs: SongRequestInfo[]) => {
    return songs.map((song) => (
      <SongCard
        key={`sr-${song.id}-${song.upvotes.length}`}
        songInfo={song}
        type={isHost ? "dj" : tipsEnabled ? "actions" : "upvoteOnly"}
        userTipAmount={myTipsQuery.data && (myTipsQuery.data[song.id] || 0)}
        upvoted={myUpvotesQuery.data.has(song.id) || false}
      />
    ));
  };

  const normalSongs = normalSongsQuery.data;
  const tippedSongs = tippedSongsQuery.data;
  const playedSongs = playedSongsQuery.data;
  // const playingFromPartyPlaylist =
  //   playlistUri === `spotify:playlist:${spotifyPlaylistId}`;
  return (
    <div>
      <Header
        leftButtonType="home"
        title={name}
        centerTitle={true}
        showHost={isHost}
        rightComponent={
          isHost ? (
            <FiSettings
              className="w-6 h-6 m-4"
              onClick={() => navigate("edit")}
            />
          ) : (
            <Link to={`/u/${hostId}`} className="shrink-0">
              <img
                src={hostImg}
                className="rounded-lg w-10 h-10"
                alt={hostId}
              />
            </Link>
          )
        }
      />
      <PartyHeaderActions isHost={isHost} />
      {/* {isHost && isSpotifyConnected ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            margin: "0.3em 0 1em 0",
          }}
        >
          <Button
            variant="outlined"
            onClick={playOnSpotify}
            disabled={!isConnected || (isPlaying && playingFromPartyPlaylist)}
          >
            {isPlaying && playingFromPartyPlaylist
              ? "Playing..."
              : "Autoplay on spotify"}
          </Button>
          <DebugP>
            <span>Spotify status: </span>
            {isConnected
              ? "Connected"
              : "Disconnected. Please play something on spotify"}
          </DebugP>
          <DebugP>
            <span>Spotify is playing: </span>
            {isPlaying ? "Yes" : "No"}
          </DebugP>
          <DebugP>
            <span>Is playing from party: </span>
            {playingFromPartyPlaylist ? "Yes" : "No"}
          </DebugP>
          <DebugP>
            <span>Current track:</span>
            {currentTrackName} - {currentArtist}
          </DebugP>
        </div>
      ) : null} */}
      <div>
        {(guestsCanAdd || isHost) && (
          <div className="flex max-w justify-center my-4">
            <button className="btn btn-gradient" onClick={addSongClicked}>
              Add a song
            </button>
          </div>
        )}
        {/* Tipped songs */}
        {tippedSongs.length ? (
          <>
            <SectionHeader>💰 Tipped Songs</SectionHeader>
            {renderSection(tippedSongs)}
          </>
        ) : null}
        {/* Other songs */}
        {tippedSongs.length ? (
          <SectionHeader>🔊️ Other Songs</SectionHeader>
        ) : null}
        {renderSection(normalSongs)}
        {/* Played songs */}
        {playedSongs.length ? (
          <>
            <SectionHeader>🎙 Played Songs</SectionHeader>
            {renderSection(playedSongs)}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default MainPartyView;
