/* This redirects users after they scan a QR code. Uses the /uqr/<userID>
 * If there is an active party for that user, redirects to that party screen
 * If there is no active party but user is found, show the user profile
 * If the user doesn't exist, redirects to the home screen
 */
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import FullScreenLoad from "../../components/FullScreenLoad";
import { useUserPartiesQuery } from "../../data/parties/queries";

function UserQRRedirect() {
  const { userId } = useParams();

  const { activeQuery } = useUserPartiesQuery(userId ?? "");

  if (activeQuery.isLoading) {
    return <FullScreenLoad debugInfo="Loading QR Code Redirect" />;
  } else if (activeQuery.data.length) {
    return <Navigate replace to={`/party/${activeQuery.data[0].id}`} />;
  }
  return <Navigate replace to={`/u/${userId}`} />;
}

export default UserQRRedirect;
