import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FullScreenLoad from "../../components/FullScreenLoad";
import Header from "../../components/Header";
import PartyCard from "../../components/Cards/PartyCard";
import { useUserContext } from "../../contexts/useUserContext";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  useAllActivePartiesQuery,
  useAllInactivePartiesQuery,
} from "../../data/parties/queries";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function Home() {
  const navigate = useNavigate();
  const user = useUserContext();

  const activePartyQuery = useAllActivePartiesQuery();
  const inactivePartyQuery = useAllInactivePartiesQuery();

  if (
    activePartyQuery.isLoading ||
    inactivePartyQuery.isLoading ||
    !inactivePartyQuery.data
  ) {
    return <FullScreenLoad debugInfo="Loading homescreen" />;
  }

  return (
    <div>
      <Header
        leftButtonType="home"
        title="Amplifi"
        centerTitle
        rightComponent={
          user ? (
            <Link to="/me">
              <img
                src={user.photoUrl || ""}
                alt="me"
                className="rounded-lg w-10 h-10"
              />
            </Link>
          ) : (
            <Link to="/login">Login</Link>
          )
        }
      />
      <div className="w-full flex justify-center mb-10 mt-5">
        <button
          className="btn btn-gradient"
          onClick={() => {
            navigate("/createParty");
          }}
        >
          Create New Party
        </button>
      </div>
      <div>
        <h1 className="font-light ml-6 text-2xl mt-9 mb-2">
          📌 Pinned Parties
        </h1>
        {activePartyQuery.data.map((party) => (
          <PartyCard
            partyId={party.id}
            key={party.id}
            name={party.name}
            numSongs={party.numSongs}
            img={
              party.topSong
                ? party.topSong.imgUrl
                : "https://static.vecteezy.com/system/resources/thumbnails/002/869/642/small/listening-to-music-black-glyph-icon-headphones-with-playing-song-hear-audio-in-headset-everyday-entertainment-and-daily-activities-silhouette-symbol-on-white-space-isolated-illustration-vector.jpg"
            }
            host={party.hostName}
            lastUpdated={party.lastUpdated}
            onClick={() => navigate(`/party/${party.id}`)}
          />
        ))}
        <h1 className="font-light ml-6 text-2xl mt-9 mb-2">🎉 Other Parties</h1>
        <InfiniteScroll
          dataLength={inactivePartyQuery.data.length}
          next={inactivePartyQuery.fetchNextPage}
          hasMore={Boolean(inactivePartyQuery.hasNextPage)}
          loader={
            <div className="flex justify-center my-4">
              <LoadingSpinner size={50} />
            </div>
          }
        >
          {inactivePartyQuery.data.map((party) => (
            <PartyCard
              partyId={party.id}
              key={party.id}
              name={party.name}
              img={
                party.topSong
                  ? party.topSong.imgUrl
                  : "https://static.vecteezy.com/system/resources/thumbnails/002/869/642/small/listening-to-music-black-glyph-icon-headphones-with-playing-song-hear-audio-in-headset-everyday-entertainment-and-daily-activities-silhouette-symbol-on-white-space-isolated-illustration-vector.jpg"
              }
              host={party.hostName}
              numSongs={party.numSongs}
              lastUpdated={party.lastUpdated}
              onClick={() => navigate(`/party/${party.id}`)}
            />
          ))}
          {/* <div className="flex justify-center mb-4">
            <button
              className="btn"
              onClick={() => {
                console.log("HIHI");
                inactivePartyQuery.fetchNextPage();
              }}
            >
              Load More
            </button>
          </div> */}
        </InfiniteScroll>
      </div>
    </div>
  );
}
