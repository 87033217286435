// Handles analytics and waiting for a login at the root level
import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useUserContext } from "../contexts/useUserContext";
import FullScreenLoad from "./FullScreenLoad";

const analytics = getAnalytics();

export default function MainRouteWrapper() {
  const user = useUserContext();
  const location = useLocation();
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: location.pathname,
    });
  }, [location]);

  if (user === null) {
    return <FullScreenLoad debugInfo="Getting Auth Data" />;
  }
  return <Outlet />;
}
