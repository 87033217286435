import {
  useFirestoreDocument,
  useFirestoreInfiniteQuery,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import {
  collection,
  doc,
  limit,
  orderBy,
  Query,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { QueryKey } from "react-query";
import { useParams } from "react-router-dom";
import { firestore } from "../../utils/firebase";
import { partyConverter } from "./converters";
import { Party } from "./types";

// TODO: Might want to change the way these hooks work and remove default values

const EMPTY_PARTY: Party = {
  id: "",
  name: "",
  hostId: "",
  hostName: "",
  hostImg: "",
  tipsEnabled: false,
  guestsCanAdd: false,
  active: false,
  pin: 0,
  createdAt: new Date("April 20, 2022 01:00:00"),
  lastUpdated: new Date("April 20, 2022 01:00:00"),
  numSongs: 0,
  topSong: null,
};

const partyCol = collection(firestore, "parties").withConverter(partyConverter);

const usePartiesQuery = (key: QueryKey, queryArg: Query<Party>) => {
  const q = query(queryArg, orderBy("lastUpdated", "desc"));
  return useFirestoreQueryData(key, q);
};

const useAllPartiesQueryGeneric = (active: boolean) => {
  const partyRef = query(partyCol, where("active", "==", active));
  const q = usePartiesQuery(
    ["parties", null, active ? "active" : "inactive"],
    partyRef
  );
  return { ...q, data: q.data ?? [] };
};

export const useAllInactivePartiesQuery = () => {
  const partyRef = query(
    partyCol,
    where("active", "==", false),
    orderBy("lastUpdated", "desc"),
    limit(10)
  );
  const q = useFirestoreInfiniteQuery(
    ["parties", null, "inactive"],
    partyRef,
    (snapshot) => {
      const lastDocument = snapshot.docs[snapshot.docs.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      // Get the next 10 documents starting after the last document fetched.
      return query(partyRef, startAfter(lastDocument));
    }
  );
  return {
    ...q,
    data: q.data
      ? q.data.pages.flatMap((page) => page.docs.map((doc) => doc.data()))
      : [],
  };
};

export const useAllActivePartiesQuery = () => useAllPartiesQueryGeneric(true);
// export const useAllInactivePartiesQuery = () =>
//   useAllPartiesQueryGeneric(false);

export const useUserPartiesQuery = (userId: string) => {
  // Returns the active and inactive parties
  const activeRef = query(
    partyCol,
    where("hostId", "==", userId),
    where("active", "==", true)
  );

  const inactiveRef = query(
    partyCol,
    where("hostId", "==", userId),
    where("active", "==", false)
  );

  const activeQ = usePartiesQuery(["parties", userId, "active"], activeRef);
  const inactiveQ = usePartiesQuery(
    ["parties", userId, "inactive"],
    inactiveRef
  );

  return {
    inactiveQuery: { ...inactiveQ, data: inactiveQ.data ?? [] },
    activeQuery: { ...activeQ, data: activeQ.data ?? [] },
  };
};

export const usePartyQuery = () => {
  // This logic could potentially be buggy, make sure we check again later
  const { partyId } = useParams();
  const partyRef = doc(partyCol, `${partyId}`).withConverter(partyConverter);
  const query = useFirestoreDocument(["party", partyId], partyRef);

  const isFound: boolean = !query.isLoading && query.data?.data() !== undefined;

  return { ...query, data: query.data?.data() ?? EMPTY_PARTY, isFound };
};
