import {
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { Party } from "./types";

// Converter used as a default to read and update a party
// Todo: Fix the timestamp types to be more robust
export const partyConverter = {
  toFirestore: (data: PartialWithFieldValue<Party>) => {
    if (data.createdAt) {
      data.createdAt = Timestamp.fromDate(data.createdAt as Date);
    }
    if (data.lastOpened) {
      data.lastOpened = Timestamp.fromDate(data.lastOpened as Date);
    }
    if (data.lastClosed) {
      data.lastClosed = Timestamp.fromDate(data.lastClosed as Date);
    }
    if (data.lastUpdated) {
      data.lastUpdated = Timestamp.fromDate(data.lastUpdated as Date);
    }

    console.log(data);

    return data;
  },
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    const { createdAt, lastOpened, lastClosed, lastUpdated } = snap.data();

    return {
      id: snap.id,
      ...snap.data(),
      createdAt: createdAt ? createdAt.toDate() : undefined,
      lastOpened: lastOpened ? lastOpened.toDate() : undefined,
      lastClosed: lastClosed ? lastClosed.toDate() : undefined,
      lastUpdated: lastUpdated.toDate(),
    } as Party;
  },
};
