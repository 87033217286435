import React from "react";

type CardBaseProps = {
  leftImg: string;
  title: string;
  subtitle?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  tipInfo?: string;
  rightInnerComponent?: React.ReactNode;
  rightOuterComponent?: React.ReactNode;
};

function CardBase({
  leftImg,
  title,
  subtitle,
  tipInfo,
  onClick,
  rightInnerComponent,
  rightOuterComponent,
}: CardBaseProps) {
  return (
    <div
      className={`flex flex-row w-full px-4 py-3
      ${onClick ? "active:bg-orange-700" : ""}
      `}
    >
      <div
        className={`flex flex-grow flex-row pr-1 items-center min-w-0 gap-2 transition-colors `}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
      >
        <img
          src={leftImg}
          alt={title}
          className="rounded-lg w-[3.75rem] h-[3.75rem]"
          referrerPolicy="no-referrer"
        />
        <div className="grow overflow-hidden">
          <h2 className="font-semibold text-lg truncate">{title}</h2>
          {subtitle && <p className="medium opacity-70">{subtitle}</p>}
          {tipInfo && (
            <p className="text-green-300 light opacity-70 text-xs ">
              {tipInfo}
            </p>
          )}
        </div>
        {rightInnerComponent}
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {rightOuterComponent}
      </div>
    </div>
  );
}

export default CardBase;
