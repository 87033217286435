import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
const firebaseConfig = {
  apiKey: "AIzaSyAPP_xKoM-srNDSssWrB6n8KL7h75rax_E",
  authDomain: "amplifi-d39a5.firebaseapp.com",
  projectId: "amplifi-d39a5",
  storageBucket: "amplifi-d39a5.appspot.com",
  messagingSenderId: "341695149416",
  measurementId: "G-ZGDXYMCYB2",
  appId: "1:341695149416:web:392b63809c5d98bd09d2dd",
};

export const firebase = initializeApp(firebaseConfig);

export const firestore = getFirestore(firebase);
export const auth = getAuth(firebase);
export const analytics = getAnalytics(firebase);
export const functions = getFunctions(firebase);
