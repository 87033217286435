/* For now, this is the MY page. Only shows the current user
 */
import { useAuthSignOut } from "@react-query-firebase/auth";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc, DocumentReference } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/useUserContext";
import { auth, firestore } from "../../utils/firebase";
import { User } from "../../data/users/types";
import {
  HeaderActionQRCode,
  HeaderActionShare,
} from "../../components/HeaderActions";
import { useUserPartiesQuery } from "../../data/parties/queries";
// import { spotifyConnectLoginUrl } from "../../utils/spotify";
// import { disconnectSpotify } from "../../data/users/mutations";
import Header from "../../components/Header";
import SectionHeader from "../../components/SectionHeader";
import PartyCard from "../../components/Cards/PartyCard";
import FullScreenLoad from "../../components/FullScreenLoad";

type Props = {
  me?: boolean;
};

function ProfilePage({ me }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const myUser = useUserContext() as User;

  // Simplify the types for User and fix this
  if (!me && myUser && params.userId === myUser.id) {
    me = true;
  }

  const userId = me ? myUser.id : params.userId;

  const { inactiveQuery, activeQuery } = useUserPartiesQuery(userId || "");

  const { mutate: signOut } = useAuthSignOut(auth);

  const userRef = doc(firestore, `users/${userId}`) as DocumentReference<User>;
  const userQuery = useFirestoreDocumentData(["user", userId], userRef);

  function onSignOut() {
    signOut();
    navigate("/");
  }

  // function onConnectSpotify() {
  //   window.location.href = spotifyConnectLoginUrl;
  // }

  // function onDisconnectSpotify() {
  //   console.log("Disconnect");
  //   disconnectSpotify(myUser.id);
  // }

  if (userQuery.isLoading) {
    return <FullScreenLoad debugInfo="Loading profile" />;
  }

  if (!userQuery.data) {
    return <div>No user found!</div>;
  }

  return (
    <div>
      <Header
        leftButtonType="back"
        title={userQuery.data.displayName ?? ""}
        centerTitle
        rightComponent={
          <img
            src={userQuery.data.photoUrl ?? ""}
            alt="me"
            className="rounded-lg w-10 h-10"
          />
        }
      />
      {me && (
        <div className="flex flex-row items-center mb-8 gap-5 justify-center">
          <HeaderActionQRCode
            title="Personal QR Code"
            QRTitle={userQuery.data.displayName ?? ""}
            url={`https://app.amplifimusic.com/uqr/${myUser.id}`}
          />
          <HeaderActionShare
            title="Share"
            url={`https://app.amplifimusic.com/u/${myUser.id}`}
          />
        </div>
      )}
      {/* <AvatarActivePartyContainer>
        <Avatar
          sx={{ width: "60px", height: "60px", margin: "0.7em" }}
          src={userQuery.data.photoUrl ?? ""}
        />
        <ActivePartyContainer></ActivePartyContainer>
      </AvatarActivePartyContainer> */}
      {me && (
        <div className="w-full flex justify-center mb-5">
          <button
            className="btn btn-gradient"
            onClick={() => {
              navigate("/createParty");
            }}
          >
            Create New Party
          </button>
        </div>
      )}
      <div>
        <SectionHeader>📌 Pinned Parties</SectionHeader>
        {activeQuery.data.length ? (
          <PartyCard
            key={activeQuery.data[0].id}
            partyId={activeQuery.data[0].id}
            name={activeQuery.data[0].name}
            img={
              activeQuery.data[0].topSong
                ? activeQuery.data[0].topSong.imgUrl
                : "https://static.vecteezy.com/system/resources/thumbnails/002/869/642/small/listening-to-music-black-glyph-icon-headphones-with-playing-song-hear-audio-in-headset-everyday-entertainment-and-daily-activities-silhouette-symbol-on-white-space-isolated-illustration-vector.jpg"
            }
            host={activeQuery.data[0].hostName}
            numSongs={activeQuery.data[0].numSongs}
            lastUpdated={activeQuery.data[0].lastUpdated}
            onClick={() => navigate(`/party/${activeQuery.data[0].id}`)}
            showOptions
            partyActive={activeQuery.data[0].active}
          />
        ) : (
          <div className="flex justify-center">
            <h2>No active party</h2>
          </div>
        )}
        {inactiveQuery.data.length ? (
          <>
            <SectionHeader>🎉 Other Parties</SectionHeader>
            {inactiveQuery.data.map((party) => (
              <PartyCard
                key={party.id}
                partyId={party.id}
                name={party.name}
                img={
                  party.topSong
                    ? party.topSong.imgUrl
                    : "https://static.vecteezy.com/system/resources/thumbnails/002/869/642/small/listening-to-music-black-glyph-icon-headphones-with-playing-song-hear-audio-in-headset-everyday-entertainment-and-daily-activities-silhouette-symbol-on-white-space-isolated-illustration-vector.jpg"
                }
                numSongs={party.numSongs}
                host={party.hostName}
                lastUpdated={party.lastUpdated}
                onClick={() => navigate(`/party/${party.id}`)}
                showOptions
                partyActive={party.active}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
      {me && (
        <>
          {/* {myUser.isSpotifyConnected ? (
            <>
              <h2>Spotify connected</h2>
              <Button variant="contained" onClick={onDisconnectSpotify}>
                Disconnect
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={onConnectSpotify}>
              Connect with Spotify
            </Button>
          )} */}
          <div className="flex justify-center max-w mt-4">
            <button
              onClick={onSignOut}
              className="btn btn-outline items-center"
            >
              Logout
            </button>
          </div>
          {/* <Button
            variant="outlined"
            onClick={onSignOut}
            sx={{ marginTop: "1em" }}
          >
            Logout
          </Button> */}
        </>
      )}
    </div>
  );
}

export default ProfilePage;
