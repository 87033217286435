// This will only allow authed users to access a page
import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../../contexts/useUserContext";

type Props = {
  children: JSX.Element | null;
};

function ProtectAuthedOnly({ children }: Props) {
  const user = useUserContext();

  if (user) {
    return children;
  } else {
    return <Navigate replace to="/login" />;
  }
}

export default ProtectAuthedOnly;
